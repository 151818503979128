import academic from "assets/images/academic.jpg";
// import LazyIframe from "components/common/iframe/lazyIframe";
import LayoutStep from "components/common/layout/layoutStep";
// import Button from "components/ui/atoms/button/button";
import { titleTrainingDays } from "data/config";
import Link from "gatsby-link";
import React from "react";

import SEO from "../../components/SEO/SEO";

const Step7 = () => {
  return (
    <LayoutStep>
      <SEO
        title={`Academic and professional guidance | ${titleTrainingDays}`}
        description={"Explanation about the options a student have when finishing the studies in our highschool."}
        canonical={"training-days/step-7"}
      />
      <div className="container step">
        <p className={"h1"}>{titleTrainingDays} - Step 7</p>
        <h1>Academic and professional guidance</h1>
        <section className="videoCart">
          <main className={"slideLeft s05"}>
            <div className={"video--bloque--a"}></div>
            <div className={"embed-iframe"}>
              <a href={"/downloads/Academic-and-professional-guidance.pdf"} rel="noreferrer" target={"_blank"}>
                <img
                  className={"videoCart__img"}
                  alt={"Which options do the students have when finishing PCI?. Slide"}
                  src={academic}
                  width={"1280"}
                  height={"720"}
                />
              </a>
            </div>
          </main>
          <aside className={"slideLeft s1"}>
            <div className="stepNavigation">
              <Link to="/training-days/step-6/#top" className={"stepNavigation__button--back "}>
                {" "}
                <span className={"stepNavigation__button--back--right"}> </span> Back{" "}
              </Link>
              <Link to="/training-days/step-8/#top" className={"stepNavigation__button--next"}>
                {" "}
                <span className={"stepNavigation__button--next--left"}> </span> Next{" "}
              </Link>
            </div>
            <p>Explanation about the options a student have when finishing the studies in our highschool.</p>
            <a
              className={"pdf"}
              href={"/downloads/Academic-and-professional-guidance.pdf"}
              rel="noreferrer"
              target={"_blank"}
            >
              Academic and professional guidance
            </a>
          </aside>
        </section>
      </div>
    </LayoutStep>
  );
};

export default Step7;
